import {executeFetch, generateSearchParams} from '@tine/tiff/dist/utils/http';

const BASE_HEADERS = { 'Accept-Encoding': 'gzip' };

export function httpGetJson(path) {
	const url = path;
	const options = {
		method: 'GET',
		headers: {
			accept: 'application/json',
			'Content-Type':  "application/json"
		}
	};
	return executeFetch(url, options);
}

export function httpPostWithStatus(path, body, additionalHeaders = {}) {
	const headers = Object.assign(additionalHeaders, BASE_HEADERS);
	const url = path;
	const options = {
		method: 'POST',
		headers,
		body
	};
	return executeFetchWithStatus(url, options);
}

export function executeFetchWithStatus(url, options) {
	return fetch(url, options)
		.then(res => {
			if (res.ok) {
				return res.json().then(data => ({status: res.status, statusText: res.statusText, body: data}));
			} else {
				// make sure the status and statusText is returned even if the result is not an ok status
				return {status: res.status, statusText: res.statusText};
			}
		})
		.catch((error) => {
			throw Error(error.message);
		});
}

export function httpGetJsonWithStatus(path, params) {
	var url = path + generateSearchParams(params);
	var options = {
		method: 'GET',
		credentials: 'same-origin'
	};
	return executeFetchWithStatus(url, options);
}


export function httpPostTextWithStatus(path, body, additionalHeaders = {}) {
	const headers = Object.assign(additionalHeaders, BASE_HEADERS);
	const url = path;
	const options = {
		method: 'POST',
		headers,
		body
	};
	return executeFetchTextWithStatus(url, options);
}

export function executeFetchTextWithStatus(url, options) {
	return fetch(url, options)
		.then(res => res.text().then(data => ({status: res.status, body: data})))
		.catch((error) => {
			throw Error(error.message);
		});
}

export function httpPostWithOnlyStatus(path, body, additionalHeaders = {}) {
	const headers = Object.assign(additionalHeaders, BASE_HEADERS);
	const url = path;
	const options = {
		method: 'POST',
		headers,
		body
	};
	return executeFetchWithOnlyStatus(url, options);
}

export function executeFetchWithOnlyStatus(url, options) {
	return fetch(url, options)
		.then(res => res.status)
		.catch((error) => {
			throw Error(error.message);
		});
}

export function isEmptyObject (obj) {
	return Object.keys(obj).length === 0 && obj.constructor === Object;
}


/*   Simple utility function for forcing something to be an array
*
*   Call by using forceArray(object)
*   forceArray will always return an array.
*   If the object we are forcing is undefined,
*   the returned array will be empty
*/
export function forceArray (object) {
	/* eslint-disable no-else-return */
	/* eslint-disable eqeqeq */
	if (!object || (typeof object === "object" && !Object.keys(object).length)) {
		return [];
	} else if (object.constructor != Array || typeof object === "string") {
		return [object];
	} else {
		return object;
	}
}
