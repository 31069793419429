import 'xp-lib-brand/index.js';
import './css/main.scss';

window.dataLayer = window.dataLayer || [];

// import js-files
import './js/app-full-screen-handler.es6';
import './js/collapsible-filters.es6';
import './js/gdpr-accept.es6';
import './js/show-hide-on-dashboard.es6';
import './js/switch-handler.es6';
import './js/tab-handler.es6';
import './js/tag-manager-helper.es6';
import './js/my-profile-obo-users.es6';


import './images/TINE_Clover_Stamp_Primary_Blue_RGB.png'; // Used in leaf-clover-line part
