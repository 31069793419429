/*
*   This content is licensed according to the W3C Software License at
*   https://www.w3.org/Consortium/Legal/2015/copyright-software-and-document
*
*   Simple accordion pattern example
*/

'use strict';

Array.prototype.slice.call(document.querySelectorAll('.a-accordion')).forEach(function (accordion) {
	const triggers = Array.prototype.slice.call(accordion.querySelectorAll('.a-accordion__trigger'));
	const accordionButtonIcon = accordion.querySelector('.a-icon');

	accordion.addEventListener('click', function (event) {
		const target = event.target;
		if (target.classList.contains('a-accordion__trigger')) {
			accordionButtonIcon.classList.toggle('a-icon-arrow_down');
			accordionButtonIcon.classList.toggle('a-icon-arrow_up');
			accordion.classList.toggle('a-accordion--true');
			target.classList.toggle('a-accordion__trigger--true');
			const accordionInnerContent = document.getElementById(target.getAttribute('aria-controls'));
			if (!(target.getAttribute('aria-expanded') === 'true')) {
				target.setAttribute('aria-expanded', 'true');
				accordionInnerContent.removeAttribute('hidden');
			} else {
				target.setAttribute('aria-expanded', 'false');
				accordionInnerContent.setAttribute('hidden', '');
			}
			event.preventDefault();
		}
	});

	// Bind keyboard behaviors on the main accordion container
	accordion.addEventListener('keydown', function (event) {
		const target = event.target;
		const key = event.which.toString();

		// 33 = Page Up, 34 = Page Down
		const ctrlModifier = (event.ctrlKey && key.match(/33|34/));

		// Is this coming from an accordion header?
		if (target.classList.contains('a-accordion__trigger')) {
			// Up/ Down arrow and Control + Page Up/ Page Down keyboard operations
			// 38 = Up, 40 = Down
			if (key.match(/38|40/) || ctrlModifier) {
				var index = triggers.indexOf(target);
				var direction = (key.match(/34|40/)) ? 1 : -1;
				var length = triggers.length;
				var newIndex = (index + length + direction) % length;

				triggers[newIndex].focus();

				event.preventDefault();
			}
			else if (key.match(/35|36/)) {
				// 35 = End, 36 = Home keyboard operations
				switch (key) {
					// Go to first accordion
					case '36':
						triggers[0].focus();
						break;
					// Go to last accordion
					case '35':
						triggers[triggers.length - 1].focus();
						break;
				}
				event.preventDefault();

			}

		}
	});
});
