import { readCookie } from '../lib/member-util.es6';

const appFullscreenBtn = document.getElementById("app-fullscreen-btn");

if (appFullscreenBtn) {
	const contentContainer = document.getElementById("usermenu-content-container");
	const fullscreenAppCookie = readCookie("fullscreenApp");

	if (fullscreenAppCookie && fullscreenAppCookie === "true") {
		contentContainer.classList.add("app-in-fullscreen");
		document.cookie = 'fullscreenApp=true; path=/';
	}

	appFullscreenBtn.addEventListener('click', () => {

		if (contentContainer.classList.contains("app-in-fullscreen")) {
			contentContainer.classList.remove("app-in-fullscreen");
			document.cookie = 'fullscreenApp=false; path=/';
		} else {
			contentContainer.classList.add("app-in-fullscreen");
			document.cookie = 'fullscreenApp=true; path=/';
		}
	});
}
