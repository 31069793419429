exports.elemInStringOrArray = (elem, arrOrStringElem) => arrOrStringElem.indexOf(elem) > -1;

/* Readcookie */
exports.readCookie = (name) => {
	var nameEQ = encodeURIComponent(name) + "=";
	var ca = document.cookie.split(';');
	for (var i = 0; i < ca.length; i++) {
		var c = ca[i];
		while (c.charAt(0) === ' ') c = c.substring(1, c.length);
		if (c.indexOf(nameEQ) === 0) return decodeURIComponent(c.substring(nameEQ.length, c.length));
	}
	return false;
};


exports.setCookie = (name,value,days) => {
	var expires = "";
	if (days) {
		var date = new Date();
		date.setTime(date.getTime() + (days*24*60*60*1000));
		expires = "; expires=" + date.toUTCString();
	}
	document.cookie = name + "=" + (value || "")  + expires + "; path=/";
};

/*   Simple utility function for forcing something to be an array
*
*   Call by using forceArray(object)
*   forceArray will always return an array.
*   If the object we are forcing is undefined,
*   the returned array will be empty
*/
const forceArray = (object) => {
	/* eslint-disable no-else-return */
	/* eslint-disable eqeqeq */
	if (!object || (typeof object === "object" && !Object.keys(object).length)) {
		return [];
	} else if (object.constructor != Array || typeof object === "string") {
		return [object];
	} else {
		return object;
	}
};

exports.forceArray = forceArray;


const removeParam = (key, sourceURL) => {
	let url = sourceURL.split("?")[0];
	let paramsArr = [];
	const queryString = (sourceURL.indexOf("?") !== -1) ? sourceURL.split("?")[1] : "";

	if (queryString !== "") {
		paramsArr = queryString.split("&");
		for (let i = paramsArr.length - 1; i >= 0; i -= 1) {
			const param = paramsArr[i].split("=")[0];
			if (param === key) {
				paramsArr.splice(i, 1);
			}
		}
		if (paramsArr.length > 0) {
			url = `${url}?${paramsArr.join("&")}`;
		}
	}
	return url;
};

exports.getInfoFromParam = (url, paramKey) => {
	let paraminfoToReturn = "";
	if (url.indexOf("?") > -1 && paramKey) {
		const queryString = (url.indexOf("?") !== -1) ? url.split("?")[1] : "";

		if (queryString !== "") {
			const paramsArr = queryString.split("&");

			paramsArr.forEach(paramKeyValue => {
				let [param, paraminfo] = paramKeyValue.split("=");

				if (param === paramKey) {
					paraminfoToReturn = decodeURIComponent(paraminfo);
				}
			});
		}
	}

	return paraminfoToReturn;
};

/**
 *
 * @param url
 * @param removeParamKeys: param keys to remove from url. Ex: ["alert", "alert-type"]
 * @param addParamObjs: param obj to add to url. Ex: [{key: "alert-type", value: "alertType"}, {alert: "alertText"}]
 * @returns {string}
 */
exports.setOrRemoveRedirectParams = (url, removeParamKeys, addParamObjs) => {
	let returningUrl = url;
	const removeParamKeysArr = forceArray(removeParamKeys);
	const addParamObjsArr = forceArray(addParamObjs);

	if (url.indexOf("?") > -1 && removeParamKeysArr) {
		for (let i = 0; i < removeParamKeysArr.length; i++) {
			returningUrl = removeParam(removeParamKeysArr[i], returningUrl);
		}
	}

	let returningParams = "";
	if (addParamObjsArr) {
		for (let i = 0; i < addParamObjsArr.length; i++) {
			returningParams += `${addParamObjsArr[i].key}=${addParamObjsArr[i].value}`;
			if (i < addParamObjsArr.length - 1) {
				returningParams += "&";
			}
		}
	}
	if (returningParams !== "") {
		if (returningUrl.indexOf("?") > -1) {
			returningUrl += "&";
		} else {
			returningUrl += "?";
		}
	}
	return `${returningUrl}${returningParams}`;
};
