const collapsableBtns = document.getElementsByClassName("collapsible-content-button");
const contentElements = document.getElementsByClassName("collapsible-container");

const closeOtherContent = (id) => {  
	if (!!contentElements && contentElements.length > 0) {
		for (let i = 0; i < contentElements.length; i++) {
			if(contentElements[i].id !== id) {
				contentElements[i].style.display = "none";
			}
		}
	}
};

const toggleOtherButtonsClass = (id) => {
	if (!!collapsableBtns && collapsableBtns.length > 0) {
		for (let i = 0; i < collapsableBtns.length; i++) {
			if(collapsableBtns[i].id !== id) {
				collapsableBtns[i].classList.remove("expanded");
			}
		}
	}
};

const toggleElement = (id) => {  
	let contentElement = document.getElementById(id);
	if (contentElement.style.display === "none") {
		contentElement.style.display = "block";
	} else {
		contentElement.style.display = "none";
	}
};

const showHide = (event) => {
	const currentBtn = event.currentTarget;
	const contentRef = currentBtn.dataset.contentreference;  
	closeOtherContent(contentRef);
	toggleOtherButtonsClass(currentBtn.id);
	toggleElement(contentRef);
	currentBtn.classList.toggle("expanded");
};

if (!!collapsableBtns && collapsableBtns.length > 0) {
	for (let i = 0; i < collapsableBtns.length; i++) {
		collapsableBtns[i].addEventListener("click", function (event) {
			showHide(event);
		});
	}
}