import { tns } from 'tiny-slider/src/tiny-slider';

(() => {
	const sliderNodes = [...document.querySelectorAll('.o-carousel--container')];
	if (sliderNodes.length) {
		sliderNodes.forEach(sliderNode => {
			if (sliderNode) {
				const responsive = { 470: { items: 2 } };
				const numElements = sliderNode.dataset.numelements || 3;
				tns({
					container: sliderNode.querySelector('.o-carousel__slider'),
					controlsContainer: sliderNode.querySelector('.o-carousel__controls'),
					loop: true,
					nav: false,
					startIndex: parseInt(sliderNode.dataset.startindex, 10) || 0,
					edgePadding: sliderNode.dataset.type === 'featured' ? 90 : 80,
					center: true,
					responsive: {
						0: {
							items: 1
						},
						768: {
							items: numElements < 3 ? numElements : 3
						},
						1024: {
							// This prevents the carousel from aligning the elements far to the right when few elements are present
							items: numElements <= 3 ? Math.max(3, numElements) : 5
						},
						...responsive
					},
					autoplay: false
				});
			}
		});
	}
})();
