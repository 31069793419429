import { executeFetchWithStatus } from '../react/utils/member-util';

const addOboUserStatusMessage = document.querySelector('.my-profile-obo-user-popup-message');
initAddOboUserForm();

const validatePhone = (phoneInput) => /^(00|\+)(47|46|354)\d{7,9}/.test(phoneInput);
const validateEmail = (emailInput) => /[^@ \t\r\n]+@[^@ \t\r\n]+\.[^@ \t\r\n]+/.test(emailInput);

function initAddOboUserForm() {
	const addOboUserForm = document.querySelector('#my-profile-add-obo-user');
	const addOboUserFormSubmitButton = document.querySelector('#my-profile-add-obo-user-submit');

	if (addOboUserFormSubmitButton && addOboUserForm?.elements) {
		const formElements = addOboUserForm.elements;
		const emailInput = formElements['email'];
		const phoneInput = formElements['mobile'];
		const phoneHelpText = document.querySelector('.phone-input-help-text');

		if (phoneHelpText) {
			phoneInput.addEventListener('blur', () => {
				if (validatePhone(phoneInput.value)) {
					phoneHelpText.style.setProperty('display', 'none');
				} else {
					phoneHelpText.style.setProperty('display', 'block');
				}
			});
		}

		addOboUserFormSubmitButton.addEventListener('click', (e) => {
			e.preventDefault();
			addOboUserFormSubmitButton.disabled = true;
			const producerNumber = formElements['producerNumber']?.value;
			const language = formElements['language']?.value;
			const email = emailInput?.value?.replace(/\s/g, '');
			const phone = phoneInput?.value?.replace(/\s/g, '').replace('+', '00');

			if (!producerNumber || !language || !email || !phone) {
				showStatusMessage('Noe gikk galt når invitasjonen skulle sendes. Forsøk å laste siden på nytt og dobbeltsjekk at all informasjon er fylt inn.');
				return;
			}
			if (!validateEmail(email)) {
				showStatusMessage('Ugyldig epost');
				addOboUserFormSubmitButton.disabled = false;
				return;
			}
			if (!validatePhone(phone)) {
				console.log('invalid phone');
				showStatusMessage('Ugyldig mobilnummer');
				addOboUserFormSubmitButton.disabled = false;
				return;
			}
			// eslint-disable-next-line max-len
			const url = `${addOboUserForm.action}?action=INVITE&&email=${email}&phone=${phone}&language=${language}&producerNumber=${producerNumber}`;
			executeFetchWithStatus(url).then(res => {
				addOboUserFormSubmitButton.disabled = false;
				if (addOboUserStatusMessage && res && res.body && res.body.message) {
					showStatusMessage(res.body.message);
				} else {
					showStatusMessage('Noe gikk galt, forsøk igjen senere.');
				}
			}).catch(() => {
				addOboUserFormSubmitButton.disabled = false;
				showStatusMessage('Noe gikk galt, prøv igjen senere eller kontakt brukerstøtte.');
			});

			addOboUserForm.reset();
		});
	}
}

const removeOboUserButtons = document.querySelectorAll('.remove-obo-user-button');
removeOboUserButtons.forEach((button) => {
	button.addEventListener('click', () => {
		const { url } = button.dataset;
		if (url) {
			button.parentElement.style.setProperty('opacity', '0.3');
			executeFetchWithStatus(url).then(res => {
				if (res && res.body && res.body.message) {
					showStatusMessage(res.body.message);
					button.parentElement.remove();
				} else {
					showStatusMessage('Noe gikk galt, forsøk igjen senere.');
				}
			}).catch(() => {
				showStatusMessage('Noe gikk galt, prøv igjen senere eller kontakt brukerstøtte.');
			});
		}
	});
});

function showStatusMessage(message, milliseconds = 6000) {
	if (!addOboUserStatusMessage) return;
	addOboUserStatusMessage.classList.add('my-profile-obo-user-popup-message--active');
	addOboUserStatusMessage.innerText = message;
	setTimeout(() => {
		addOboUserStatusMessage.classList.remove('my-profile-obo-user-popup-message--active');
		addOboUserStatusMessage.innerText = '';
	}, milliseconds);
}
