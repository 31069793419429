import 'lazysizes/plugins/respimg/ls.respimg.js';
import 'lazysizes';
import './js/index.es6';

// Import assets that need to be copied to assets by webpack
// And that isnt refered to in other assets
import './images/logo_tine.svg'; // Used in header

// Conditionals for imports and chunks
if (document.querySelector('.menu-anchor')) {
	import(/* webpackChunkName: "menuAnchor" */ './parts/menu-anchor/index.es6');
}
