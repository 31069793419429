const collapsableLastSettlement = document.getElementsByClassName("last-settlement-collapsible");

if (!!collapsableLastSettlement && collapsableLastSettlement.length > 0) {
	for (let i = 0; i < collapsableLastSettlement.length; i++) {
		collapsableLastSettlement[i].addEventListener("click", function () {
			const content = this.nextElementSibling;

			this.classList.toggle("expanded");

			if (!content.style.display || content.style.display === "none") {
				content.style.display = "block";
			} else {
				content.style.display = "none";
			}
		});
	}
}

const collapsableNews = document.getElementsByClassName("news-collapsible");

if (!!collapsableNews && collapsableNews.length > 0) {
	for (let i = 0; i < collapsableNews.length; i++) {
		collapsableNews[i].addEventListener("click", function () {
			this.parentElement.classList.toggle("expanded");
		});

		if (window.innerWidth > 500) {
			collapsableNews[i].parentElement.classList.add("expanded");
		}
	}
}
