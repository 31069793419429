const vacancyModal = (modalContainer) => {
	const body = document.getElementsByTagName("body")[0];
	const header = document.getElementsByTagName("header")[0];
	const footer = document.getElementsByTagName("footer")[0];
	const breadcrumbs = document.querySelector(".o-breadcrumbs");
	const mainContainer = document.querySelector(".main-container");
	const articleHeading = document.querySelector(".t-heading--article")
		? document.querySelector(".t-heading--article").innerText
		: "stillingen";
	const modalTitle = document.querySelector(".vacancy-title");
	const modalBtnOpen = document.querySelector(".vacancy-btn--modal");
	const modalBtnSubmit = document.querySelector(".vacancy-btn--form");
	const modalBtnClose = document.querySelector(".vacancy-modal__close");
	const modalForm = document.querySelector(".vacancy-modal__form");
	const modalThankYou = document.querySelector(".vacancy-modal__thank-you");
	
	header.before(modalContainer); // move modal container HTML further up to cover whole site
	if (modalTitle) modalTitle.innerText = articleHeading; // set modal title

	// toggle same class for array of nodes
	const addOrRemoveClass = (htmlNodes, className, enabled) => {
		if (!Array.isArray(htmlNodes) || !className) return false; // check arguments

		for (let i = 0; i < htmlNodes.length; i++) {
			if(enabled === true && htmlNodes[i]) {
				htmlNodes[i].classList.add(className);
			}
			if(enabled === false && htmlNodes[i]) {
				htmlNodes[i].classList.remove(className);
			}
		}
	};

	const fadeBackground = (enabled) => {
		const classFadeBrightness = 'vacancy-modal__fade-bg-brightness';
		const classFadeBackground = 'vacancy-modal__fade-bg-color';

		if (enabled === true) {
			body.classList.add(classFadeBackground);
			addOrRemoveClass(
				[header, footer, breadcrumbs, mainContainer],
				classFadeBrightness,
				enabled
			);
		} else {
			body.classList.remove(classFadeBackground);
			addOrRemoveClass(
				[header, footer, breadcrumbs, mainContainer],
				classFadeBrightness,
				enabled
			);
		}
	}

	const modalOpen = () => {
		fadeBackground(true);
		modalContainer.style.top = window.scrollY + "px"; // center modal
		modalContainer.style.display = "flex";
		modalBtnClose.focus();
	};

	const modalClose = () => {
		fadeBackground(false);
		modalContainer.style.display = "none";
		modalBtnOpen.focus();
	};

	const isConnectedToModal = (element) => {
		if (!element) return false; // check argument

		let currentNode = element;
		while (currentNode !== null) {
			if (currentNode.classList.contains('vacancy-modal')) return true;
			currentNode = currentNode.parentElement;
		}

		return false;
	}

	const clickOutside = (event) => {
		if (
			modalContainer.style.display === 'flex' &&
			event.target.className !== modalBtnOpen.className
		) {
			// add if "sub-level" to process the easy part first - saving cpu(?)
			if (
				!isConnectedToModal(event.target) ||
				event.target.className === modalContainer.className ||
				event.target.className === modalBtnClose.className
			) {
				modalClose();
			}
		}
	};

	const responseMessage = (text) => {
		modalForm.style.display = "none";
		modalThankYou.style.display = "inline-block";
		modalThankYou.innerText = text.trim();
	};

	// send form data to API
	const registerApplicant = (event) => {
		if (modalForm.checkValidity()) { // HTML validation is ok
			event.preventDefault();

			const formValues = {};
			const formElements = Array.from(modalForm.children);
			
			formElements
				.filter(node => node.nodeName === "INPUT")
				.map(input => {
					if (input.name === "name") formValues.name = input.value;
					if (input.name === "phone") formValues.phone = input.value;
					if (input.name === "email") formValues.email = input.value;
					if (input.name === "linkedin") formValues.linkedin = input.value;
					if (input.name === "dreamJob") formValues.dreamJob = input.value;
				});

			fetch('/_/service/no.tine.tine/webcruiter?' + new URLSearchParams({
				title: articleHeading,
				name: formValues.name,
				phone: formValues.phone,
				email: formValues.email,
				linkedin: formValues.linkedin,
				dreamJob: formValues.dreamJob
			}))
				.then(response => { // success
					if (response.status !== 200) {
						responseMessage(`
							Det har oppstått en feil.
							Vennligst kontakt sentralbordet på +47 75 66 30 80.
						`);
					}
				})
				.catch(() => { // error
					responseMessage(`
						Det har oppstått en feil.
						Vennligst kontakt sentralbordet på +47 75 66 30 80.
					`);
				});
			
			responseMessage(`
				Takk for din interesse.
				Du vil bli kontaktet om du er aktuell for stillingen.
			`);
		}
	};

	// set event listeners
	modalBtnOpen.addEventListener('touchend', () => modalOpen());
	modalBtnOpen.addEventListener('mouseup', () => modalOpen());

	body.addEventListener('touchend', (e) => clickOutside(e));
	body.addEventListener('mouseup', (e) => clickOutside(e));

	modalBtnSubmit.addEventListener('touchend', (e) => registerApplicant(e));
	modalBtnSubmit.addEventListener('mouseup', (e) => registerApplicant(e));

	// keyboard
	document.addEventListener("keyup", (e) => {
		if ( // open on button
			e.key === "Enter" &&
			e.target.className.includes(modalBtnOpen.className)
		) {
			modalOpen();
		}

		if ( // hide if keypress "esc" || "enter" when on X icon)
			e.key === "Escape" ||
			(e.key === "Enter" && e.target.className === modalBtnClose.className)
		) {
			modalClose();
		}

		// focus trap
		if (e.key === "Tab" && modalContainer.style.display === 'flex') {
			if (e.shiftKey) {
				// shift + tab
				if (isConnectedToModal(document.activeElement) === false) {
					modalBtnSubmit.focus();
				}
			} else {
				// tab
				if (isConnectedToModal(document.activeElement) === false) {
					modalBtnClose.focus();
				}
			}
		}
	});
};

const VACANCY_MODAL_CONTAINER = document.querySelector(".vacancy-modal") || null;

if (VACANCY_MODAL_CONTAINER) {
	vacancyModal(VACANCY_MODAL_CONTAINER); // run function
}
