const advisorEmailElements = document.getElementsByClassName("advisor-email");
if (advisorEmailElements) {
	for (let i = 0; i < advisorEmailElements.length; i++) {
		advisorEmailElements[i].addEventListener('click', () => {
			window.dataLayer.push({
				'event': 'find-advisor-specific-email-click'
			});
		});
	}
}

const editorContentElement = document.getElementById("default-editor-content");
if (editorContentElement) {
	const spaContent = document.getElementById("app-main");

	if (!spaContent) {
		window.dataLayer.push({
			'editorContent': 'true'
		});

		window.dataLayer.push({
			'event': 'editorContent'
		});

		if (editorContentElement.getAttribute('data-loggedin') && editorContentElement.getAttribute('data-loggedin') === 'true') {
			window.dataLayer.push({
				'loggedin': 'true'
			});

			window.dataLayer.push({
				'event': 'loggedin'
			});
		}
	}
}

