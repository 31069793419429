function toggleClass(el, className) {
	if (el.classList) {
		el.classList.toggle(className);
	} else {
		const classes = el.className.split(' ');
		const existingIndex = classes.indexOf(className);

		if (existingIndex >= 0) {
			classes.splice(existingIndex, 1);
		} else {
			classes.push(className);
		}
		el.className = classes.join(' ');
	}
}

exports.headerMenu = () => {
	document.querySelectorAll('.o-header').forEach(el => {
		setupMenu(el);
		setupSearch(el);
	});
};

/**
 * This function Initialize the hamburgermenu button on header if it exists in the DOM
 * @param {HTMLElement} headerElement
 */
function setupMenu(headerElement) {
	const menuButton = headerElement?.querySelector('.o-header--header__hamburger');
	const menu = headerElement?.querySelector('.o-header-menu');
	const menuButtonIcon = menuButton?.querySelector('.a-icon-menu');
	const menuButtonClose = menuButton?.querySelector('.a-icon-close');
	

	if (menuButton && headerElement && menu && menuButtonIcon && menuButtonClose) {

		handleClickOutside(menu, menuButton, () => closeMenu(headerElement));
		menuButton.addEventListener('click', () => {
			toggleClass(menuButtonIcon, 'hidden');
			toggleClass(menuButtonClose, 'hidden');
			toggleClass(menuButton, 'o-header--header__cross');
			toggleClass(menu, 'hidden');
			toggleClass(menu, 'displayMenu-true');
			toggleClass(menu, 'displayMenu-false');
			// Fixes IE not opening menu on frontpage
			if (!menuButtonClose.classList.contains('hidden') && menu.classList.contains('hidden')) {
				menu.classList.remove('hidden');
			}
		});
	}
}

/**
 * This function Initialize the search box element on header if it exists in the DOM
 * @param {HTMLElement} headerElement
 */
function setupSearch(headerElement) {
	const searchButton = headerElement?.querySelector('.o-header--header__search-button');
	const searchButtonIcon = searchButton?.querySelector('.a-icon-search');
	const searchButtonClose = searchButton?.querySelector('.a-icon-close');
	const searchMenu = headerElement?.querySelector('.o-header--search');
	const searchInput = headerElement?.querySelector('.o-header--search__input');

	if (searchButton && headerElement && searchButtonIcon && searchButtonClose && searchMenu) {
		handleClickOutside(searchMenu, searchButton, () => closeSearchMenu(headerElement));

		searchButton.addEventListener('click', () => {
			toggleClass(searchButtonIcon, 'hidden');
			toggleClass(searchButtonClose, 'hidden');
			toggleClass(searchButton, 'o-header--header__cross');
			toggleClass(searchMenu, 'hidden');

			if (!searchMenu.classList.contains('hidden') && searchInput) {
				searchInput.focus();
			}
		});
	}
}

function closeMenu(headerElement) {
	const menuElement = headerElement.querySelector('.o-header-menu');
	if (menuElement.className.indexOf('hidden') === -1) {
		menuElement.classList.add('hidden');
		menuElement.className.replace('displayMenu-true', 'displayMenu-false');
		const menuButton = headerElement.querySelector('.o-header--header__hamburger');
		const menuButtonIcon = menuButton.querySelector('.a-icon-menu');
		const menuButtonClose = menuButton.querySelector('.a-icon-close');
		toggleClass(menuElement, 'displayMenu-false');
		toggleClass(menuButton, 'o-header--header__cross');
		toggleClass(menuButtonIcon, 'hidden');
		toggleClass(menuButtonClose, 'hidden');
	}
}

function closeSearchMenu(headerElement) {
	const searchElement = headerElement.querySelector('.o-header--search');
	if (searchElement.className.indexOf('hidden') === -1) {
		searchElement.classList.add('hidden');
		const searchButton = headerElement.querySelector('.o-header--header__search-button');
		const searchButtonIcon = searchButton.querySelector('.a-icon-search');
		const searchButtonClose = searchButton.querySelector('.a-icon-close');
		toggleClass(searchButton, 'o-header--header__cross');
		toggleClass(searchButtonIcon, 'hidden');
		toggleClass(searchButtonClose, 'hidden');
	}
}

function handleClickOutside(domElement, triggerButton, callback) {
	document.addEventListener('click', (event) => {
		const { target } = event;
		if (target !== triggerButton && !triggerButton.contains(target) && domElement && target !== domElement && !domElement.contains(target)) {
			callback();
		}
	});
}
