import { httpPostWithOnlyStatus } from "../react/utils/member-util";
import { setCookie } from "../lib/member-util.es6";

const cowGdprAcceptBtn = document.getElementById("medlem-gdpr-accept");
const goatGdprAcceptBtn = document.getElementById("medlem-gdpr-accept-goat");
const gdprwrapper = document.getElementById("gdprwrapper");
const cowGdprContainer = document.getElementById('medlem-gdpr');
const goatGdprContainer = document.getElementById('medlem-gdpr-goat');

const msBeforeTimingOut = 2 * 1000; // 2 seconds

if (cowGdprAcceptBtn) {
	const producerId = cowGdprAcceptBtn.getAttribute('data-producerid');
	const errorMessage = document.getElementById('cow-save-error');
	const successMessage = document.getElementById('cow-save-success');

	cowGdprAcceptBtn.addEventListener("click", () => {
		httpPostWithOnlyStatus(`/api/internal/rest/v1/core/settings/owners/${producerId}/ownerSettings`,
			"json=%7B%22registrations%22%3A%5B%7B%22cowControlConsent%22%3A%22true%22%7D%5D%7D",
			{'Content-Type':  "application/x-www-form-urlencoded; charset=UTF-8", 'X-HTTP-Method-Override': 'put'})
			.catch(() => {})
			.then((responseStatus) => {
				if (responseStatus && (responseStatus === 202 || responseStatus === 200)) {
					setCookie('cowGdprAccept', 'true');
					successMessage.style.display = 'block';
					setTimeout(() => {
						cowGdprContainer.style.display = 'none';
						if (!goatGdprContainer || goatGdprContainer.style.display === 'none') {
							gdprwrapper.style.display = 'none';
						}
					}, msBeforeTimingOut);
				} else {
					errorMessage.style.display = 'block';
				}
			});
	});
}

if (goatGdprAcceptBtn) {
	const producerId = goatGdprAcceptBtn.getAttribute('data-producerid');
	const errorMessage = document.getElementById('goat-save-error');
	const successMessage = document.getElementById('goat-save-success');

	goatGdprAcceptBtn.addEventListener("click", () => {
		httpPostWithOnlyStatus(`/geitapi/rest/internal/owners/${producerId}/consent`,
			'',
			{'Content-Type':  "application/x-www-form-urlencoded; charset=UTF-8", 'X-HTTP-Method-Override': 'put'})
			.catch(() => {})
			.then((responseStatus) => {
				if (responseStatus && (responseStatus === 200 || responseStatus === 202)) {
					setCookie('goatGdprAccept', 'true');
					successMessage.style.display = 'block';
					setTimeout(() => {
						goatGdprContainer.style.display = 'none';
						if (!cowGdprContainer || cowGdprContainer.style.display === 'none') {
							gdprwrapper.style.display = 'none';
						}
					}, msBeforeTimingOut);
				} else {
					errorMessage.style.display = 'block';
				}
			});
	});
}
