(() => {
	const playButtons = document.getElementsByClassName('js-play-button');
	if (playButtons) {
		Array.from(playButtons).forEach((playButton) => {
			const container = playButton.parentElement;
			const youtubeLink = playButton.dataset.youtubelink;
			const youtubeTitle = playButton.dataset.youtubetitle;

			playButton.addEventListener('click', () => {
				container.innerHTML = '';
				const videoPlayer = document.createElement('div');
				videoPlayer.setAttribute('class', 'm-media__youtube');
				const iFrame = document.createElement('IFRAME');
				iFrame.setAttribute(
					'src',
					`https://www.youtube.com/embed/${youtubeLink}`
				);
				iFrame.setAttribute('frameBorder', '0');
				iFrame.setAttribute('allowfullscreen', '1');
				iFrame.setAttribute('allow', 'autoplay');
				iFrame.setAttribute('title', youtubeTitle);
				iFrame.setAttribute('class', 'm-media__iframe');
				if (youtubeLink.includes('id=youtube-video')) {
					iFrame.setAttribute('id', 'youtube-video');
				}
				videoPlayer.appendChild(iFrame);
				container.appendChild(videoPlayer);
			});
		});
	}
	// Automatically click the first play button if the URL contains the word "sommer"
	if (window.location.href.includes('sommer')) {
			playButtons[0].click();
	}
})();
