import {httpPostWithOnlyStatus} from "../react/utils/member-util";

const consentSwitches = document.getElementsByClassName("consent-switch");

const getAllConsents = (consentSwitches) => {
	const returnObj = {};
	for (let i = 0; i < consentSwitches.length; i++) {
		const consentSwitch = consentSwitches[i];
		if (consentSwitch.id.indexOf("email") > -1) {
			returnObj.email = consentSwitch.checked;
		} else if (consentSwitch.id.indexOf("warning") > -1) {
			returnObj.warning = consentSwitch.checked;
		}
	}
	return returnObj;
};

const hideAllErrors = (emailErrorContainer, warningErrorContainer) => {
	emailErrorContainer.classList.add('hidden-error');
	warningErrorContainer.classList.add('hidden-error');
};

const hideAllSuccess = (emailErrorContainer, warningErrorContainer) => {
	emailErrorContainer.classList.add('hidden-success');
	warningErrorContainer.classList.add('hidden-success');
};

if (!!consentSwitches && consentSwitches.length > 0) {
	const msBeforeTimingOut = 2 * 1000; // 2 seconds
	for (let i = 0; i < consentSwitches.length; i++) {
		const currentSwitch = consentSwitches[i];
		const emailErrorContainer = document.getElementById('consent-switch-error-email');
		const warningErrorContainer = document.getElementById('consent-switch-error-warning');
		const emailSuccessContainer = document.getElementById('consent-switch-success-email');
		const warningSuccessContainer = document.getElementById('consent-switch-success-warning');

		currentSwitch.addEventListener('change', (event) => {
			const currentSwitch = event.currentTarget;
			const inputCheckedStateAfterTheChange = currentSwitch.checked;
			hideAllErrors(emailErrorContainer, warningErrorContainer);
			hideAllSuccess(emailSuccessContainer, warningSuccessContainer);

			const consentStatus = getAllConsents(consentSwitches);
			const producernumberHolder = document.getElementById('producernumber-holder');
			const producerId = producernumberHolder.getAttribute('data-producernr');
			const producerWorksWith = producernumberHolder.getAttribute('data-producerworkswith');

			httpPostWithOnlyStatus(`/minprofilapi/api/producers/${producerWorksWith}/${producerId}/electronicCommunication`, `${JSON.stringify(consentStatus)}`, {accept: 'application/json', 'Content-Type':  "application/json"})
				.catch(() => {})
				.then((responseStatus) => {
					if (responseStatus && responseStatus === 200) {
						if (currentSwitch.id.indexOf("email") > -1) {
							emailSuccessContainer.classList.remove('hidden-success');
							setTimeout(() => {
								emailSuccessContainer.classList.add('hidden-success');
							}, msBeforeTimingOut);
						} else if (currentSwitch.id.indexOf("warning") > -1) {
							warningSuccessContainer.classList.remove('hidden-success');
							setTimeout(() => {
								warningSuccessContainer.classList.add('hidden-success');
							}, msBeforeTimingOut);
						}
					} else {
						// go back to what it was before change event happened
						currentSwitch.checked = !inputCheckedStateAfterTheChange;

						if (currentSwitch.id.indexOf("email") > -1) {
							emailErrorContainer.classList.remove('hidden-error');
						} else if (currentSwitch.id.indexOf("warning") > -1) {
							warningErrorContainer.classList.remove('hidden-error');
						}
					}
				});
		});
	}
}
