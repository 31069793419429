// Method #1: Detect IE 10 and IE 11
function isIE1011() {
	// IE 10 and IE 11
	return /Trident\/|MSIE/.test(window.navigator.userAgent);
}



const showBrowserAlert = (function () {
	const unsupportedBrowserModals = document.getElementsByClassName('unsupported-browser');
	if (unsupportedBrowserModals && unsupportedBrowserModals[0]) {
		const unsupportedBrowserModal =unsupportedBrowserModals[0];
		const isIE11 = isIE1011();

		if (isIE11) {
			unsupportedBrowserModal.innerHTML = 'NB! tine.no vises ikke optimalt fordi nettleseren din er gammel - bytt nettleser nå! Internet Explorer er i ferd med å bli utdatert, både funksjonsmessig og sikkerhetsmessig Vi anbefaler å bytte til <a class="chrome" href="https://www.google.com/intl/no/chrome/">Google Chrome</a><a href="#" class="closeunsupported">X</a>';
			unsupportedBrowserModal.style.display = 'block';
		}

		const closeUnsupporteds = document.getElementsByClassName('closeunsupported');
		if (closeUnsupporteds && closeUnsupporteds[0]) {
	     var closeUnsupported = closeUnsupporteds[0];
		}

		document.addEventListener('click', (event) => {
			const { target } = event;
			if (target == closeUnsupported) {
				unsupportedBrowserModal.style.display = 'none';
			}
		});
	}
});



document.addEventListener('DOMContentLoaded', showBrowserAlert);
