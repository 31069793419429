import objectFitImages from 'object-fit-images';
import headerMenu from './header.es6';
import './play-button.es6';
import './accordion.es6';
import './searchpage.es6';
import './carousel.es6';
import './unsupported-browser.es6';
import './lazy-hack.es6';
import './vacancy-modal.es6';

objectFitImages(null, { watchMQ: true });
headerMenu.headerMenu();
