document.addEventListener("click", function(e){
  const target = e.target.closest(".o-search-page--next-page");
	const targeturl = e.target.getAttribute('href');

	if(target && targeturl.includes('sok/')){
		e.preventDefault();
		const request = new XMLHttpRequest();
		request.open('GET', e.target.getAttribute('href'), true);
		request.onload = () => {
			if (request.status >= 200 && request.status < 400) {
				e.target.outerHTML = request.responseText;
			}
		};
		request.send();
	}
});
