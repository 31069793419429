import {getInfoFromParam, setOrRemoveRedirectParams} from '../lib/member-util.es6';

const tabElements = document.getElementsByClassName("tab-element");

const setAllInactive = (tabBtns, tabPanels) => {
	for (let b = 0; b < tabBtns.length; b++) {
		const tabBtn = tabBtns[b];
		tabBtn.removeAttribute("aria-selected");
		tabBtn.classList.remove("active-tab-btn");
	}
	for (let p = 0; p < tabPanels.length; p++) {
		const tabPanel = tabPanels[p];
		tabPanel.classList.remove("active-tab-panel");
	}
};

const setParam = (idOfActivePanel, url) => {
	window.history.replaceState(null, null, setOrRemoveRedirectParams(url,
		['activeTab'],
		[{key: 'activeTab', value: idOfActivePanel}]
	)); // or pushState
};

const setActiveTab = (currentButton, currentPanel, url) => {
	currentButton.setAttribute("aria-selected", "true");
	currentButton.classList.add("active-tab-btn");
	currentPanel.classList.add("active-tab-panel");
	setParam(currentPanel.id, url);
};

if (!!tabElements && tabElements.length > 0) {
	const url = location.href;
	const activeTab = getInfoFromParam(url, 'activeTab');

	let firstTabElement = null;
	let title = 'Tabvisning';
	const h1Elem = document.getElementsByTagName("h1");

	if (!!h1Elem && h1Elem.length > 0) {
		title = document.getElementsByTagName("h1")[0].textContent;
	}
	const tabBtns = document.getElementsByClassName("tab-btn");
	const tabPanels = document.getElementsByClassName("tab-panel");

	const buttonContainer = document.createElement("div");
	buttonContainer.classList.add("tab-btn-container");
	buttonContainer.setAttribute("role", "tablist");
	buttonContainer.setAttribute("aria-label", title);

	for (let i = 0; i < tabElements.length; i++) {
		const tabPanelId = `tab-panel-${i}`;
		const currentElement = tabElements[i];

		const currentButton = currentElement.getElementsByClassName("tab-btn")[0];
		buttonContainer.appendChild(currentButton);
		const currentPanel = currentElement.getElementsByClassName("tab-panel")[0];

		currentButton.setAttribute("aria-controls", tabPanelId);
		currentPanel.id = tabPanelId;

		if (i === 0) {
			firstTabElement = currentElement;
		}

		if (activeTab) {
			if (activeTab === tabPanelId) {
				// set the activeTab panel and btn as active
				setActiveTab(currentButton, currentPanel, url);
			}
		} else {
			if (i === 0) {
				// set the first panel and btn as active
				setActiveTab(currentButton, currentPanel, url);
			}
		}

		currentButton.addEventListener('click', () => {
			setAllInactive(tabBtns, tabPanels);
			setActiveTab(currentButton, currentPanel, url);
		});
	}
	firstTabElement.parentNode.insertBefore(buttonContainer, firstTabElement);
}
